.is--strength {
  .rwd002-detail {
    padding: 0;
    &__entry {
      max-width: unset;
    }
  }
  @include pc {
    .rwd002-container {
      margin-top: 55px;
    }
    .rwd002-detail {
      margin-top: 0;
    }
  }
  
  .img_ttl .ttl_wrap {
    background-image: url(/assets/img/strength/main@2x.jpg);
  }
  
  .img_text {
    .container {
      max-width: calc(50% + 480px);
      display: flex;
      align-items: center;
    }
    .img_wrap {
      flex: 1;
      picture {
        display: block;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    .text_wrap {
      width: min(48%, 470px);
      margin-left: 60px;
      .num_wrap {
        width: 48px;
      }
      h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 1.4;
        margin-top: 16px;
        letter-spacing: .14em;
        strong {
          color: $thema-gr;
        }
      }
      p {
        font-size: 15px;
        line-height: 2.2;
        margin-top: 20px;
        letter-spacing: .1em;
      }
    }
    @include pc {
      &:not(.rev) .container {
        margin-left: 0;
        padding-left: 0;
      }
      &.rev {
        .container {
          margin-right: 0;
          padding-right: 0;
          flex-direction: row-reverse;
        }
        .text_wrap {
          margin-left: 0;
          margin-right: 60px;
        }
      }
      @include max(1200px){
        &:not(.img_icon) .img_wrap {
          picture {
            height: 320px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  
  .block-lead {
    padding-bottom: 104px;
    .lead {
      font-size: 17px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: .28em;
      text-align: center;
      strong {
        color: $thema-gr;
      }
    }
    .img_text {
      margin-top: 80px;
      .container {
        position: relative;
      }
      .img_wrap {
        picture {
          display: block;
          max-width: 374px;
          @include pc {
            @include max(1200px){
              height: 410px;
              max-width: calc(100% - 140px);
            }
          }
        }
      }
      .chara_wrap {
        width: 209px;
        position: absolute;
        right: 20px;
        bottom: -46px;
        @include min(1250px){
          left: 880px;
          right: auto;
        }
      }
    }
  }

  @keyframes imgCarousel {
    100% {
      transform: translateX(-3148px);
      @include sp {
        transform: translateX(-2260px);
      }
    }
  } 

  .block-works {
    padding-bottom: 36px;
    background-color: $thema-gr;
    @include pc {
      .container {
        min-height: 560px;
      }
      .img_text {
        .img_wrap {
          height: 560px;
          position: relative;
          picture {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 680px;
          }
        }
      }
    }
    .img_text {
      h3 {
        color: #fff;
        strong {
          color: #FFFF00;
        }
      }
      p {
        color: #fff;
      }
      .btn_wrap {
        margin-top: 28px;
        .link_btn {
          background-color: #fff;
          color: $thema-gr;
          &::before, &::after {
            background-color: $thema-gr;
          }
          &:hover {
            background-color: $thema-bl;
            color: #fff;
            &::before, &::after {
              background-color: #fff;
            }
          }
        }
      }
    }
  
    .carousel_wrap {
      overflow: hidden;
      ul {
        display: flex;
        animation: imgCarousel 70s linear infinite;
        width: 6288px;
        column-gap: 8px;
        li {
          width: 3140px;
          picture {
            display: block;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
  
  .block-other {
    margin-top: 100px;
    .img_text.rev {
      margin-bottom: 110px;
    }
  }
  
  .block-extra {
    margin-top: 90px;
    .border_wrap {
      padding: 56px 76px 56px 65px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 200px 1fr;
      border: 2px solid #EFEFEF;
      border-radius: 5px;
      + .border_wrap {
        margin-top: 26px;
      }
      h4 {
        font-size: 18px;
        font-weight: 700;
        color: $thema-gr;
        line-height: 1;
      }
      ul {
        display: grid;
        grid-template-columns: 255px 1fr;
        row-gap: 15px;
        li {
          line-height: 1;
          font-size: 13px;
          &::before {
            content: '';
            display: inline-block;
            position: relative;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $thema-gr;
            margin-right: 10px;
          }
        }
      }
      table {
        width: 100%;
        margin-top: -10px;
        tr {
          &:not(:last-of-type){
            th, td {
              border-bottom: 1px solid #ECEEF3;
            }
          }
          th, td {
            font-size: 14px;
            line-height: 1;
            padding-block: 15px;
          }
          th {
            font-weight: 500;
          }
          td {
            text-align: right;
            padding-right: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  @include sp {
    .rwd002-detail {
      margin: 25px 0;
    }
    
    .img_ttl .ttl_wrap {
      background-image: url(/assets/img/strength/main_sp@2x.jpg);
      @media(min-width:720px){
        background-image: url(/assets/img/strength/main@2x.jpg);
      }
    }
    
    .img_text {
      .container {
        display: block;
        margin-inline: auto;
        padding-inline: 25px;
      }
      .text_wrap {
        width: 100%;
        margin-left: 0;
        margin-top: 26px;
        .num_wrap {
          margin: auto;
        }
        h3 {
          font-size: 22px;
          margin-top: 14px;
          letter-spacing: .14em;
          text-align: center;
        }
        p {
          line-height: 2;
          margin-top: 15px;
        }
      }
    }
    
    .block-lead {
      padding-bottom: 67px;
      .lead {
        font-size: 15px;
        line-height: 2;
        letter-spacing: .12em;
      }
      .img_text {
        margin-top: 30px;
        .img_wrap {
          picture {
            display: block;
            width: 240px;
            margin: auto;
          }
        }
        .chara_wrap {
          width: min(100%, 400px);
          position: relative;
          right: 0;
          bottom: 0;
          margin: 50px auto 0;
        }
      }
      @media(min-width:720px){
        .img_text{
          .img_wrap{
            picture{
              width: 100%;
            }
          }
          .chara_wrap{
            margin: 50px auto;
          }
        }
      }
    }
    
    .block-works {
      padding-block: 76.53vw 30px;
      position: relative;
      .img_text {
        .img_wrap {
          width: 107%;
          position: absolute;
          left: -14vw;
          top: -10vw;
        }
        .text_wrap {
          margin-top: 0;
          position: relative;
          z-index: 1;
        }
        .btn_wrap {
          margin-top: 18px;
        }
      }
    
      .carousel_wrap {
        margin-top: 50px;
        ul {
          width: 4515px;
          column-gap: 5px;
          li {
            width: 2225px;
          }
        }
      }
    }
    
    .block-other {
      margin-top: 48px;
      .img_text.rev {
        margin-bottom: 40px;
      }
    }
    
    .block-extra {
      margin-top: 46px;
      .border_wrap {
        padding: 35px 20px 42px;
        display: block;
        + .border_wrap {
          margin-top: 14px;
          padding-bottom: 5px;
        }
        h4 {
          text-align: center;
        }
        ul {
          display: block;
          margin-top: 25px;
          li {
            + li {
              margin-top: 13px;
            }
          }
        }
        table {
          margin-top: 10px;
          tr {
            padding: 10px 3px;
            display: block;
            &:not(:last-of-type){
              border-bottom: 1px solid #ECEEF3;
              th, td {
                border: none;
              }
            }
            th, td {
              padding-block: 0;
              line-height: 1.2;
              display: block;
              width: 100%;
            }
            td {
              text-align: left;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}