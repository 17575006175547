$gnav-height-md: 120px;
$gnav-height-xs: 55px;
.rwd002-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 99;
  position: relative;
  @include media-up(lg) {
    padding-inline: 0 40px;
  }
  @include media-down(lg) {
    height: 70px;
  }

  &__logo {
    width: auto;
  }
  &__logo-link {
    padding-left: 154px;
    position: relative;
    height: 100px;
    display: flex;
    align-items: center;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 137px;
      height: auto;
      border-bottom-right-radius: 7px;
    }
    span {
      font-size: 17px;
      font-weight: 700;
      color: $thema-bl;
    }
    @include pc {
      @include max(1040px){
        padding-left: clamp(105px, 11.5vw, 110px);
        img {
          width: 100px;
          height: 100px;
        }
        span {
          font-size: clamp(12px, 1.3vw, 14px);
        }
      }
    }
    @include sp {
      padding-left: 113px;
      position: relative;
      height: 70px;
      img {
        width: 90px;
      }
      span {
        font-size: 16px;
      }
    }
  }
}
.rwd002-header__drawerBtn {
  @extend .btn;
  height: 100%;
  position: relative;
  z-index: 101;
  
  @include media-down(lg) {
    width: 100px;
    background-color: fade-out($white, 0.5);
  }
  @include media-down(md) {
    width: 55px;
  }
  &:focus {
    box-shadow: none;
  }
  @include media-up(lg) {
    display: none;
  }
  &__bars {
    stroke: $body-color;
  }
  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
    stroke: $thema-gr;
    stroke-width: 2px;
    stroke-linecap: round;
  }
  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }
  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }
  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}