.is--service {
  &.is--list {
    .rwd002-block-content {
      &__inner {
        display: grid;
        grid-template-columns: repeat(4, 100px);
        gap: 10px 15px;
        a.cate {
          display: grid;
          place-content: center;
          width: 100%;
          height: 30px;
          border: 2px solid #373737;
          color: #373737;
          box-sizing: border-box;
          border-radius: 15px;
          margin: 0;
          font-size: 14px;
          &.current, &:hover {
            background-color: #373737;
            border-color: #373737;
            color: #fff;
          }
        }
      }
    }
    .category_wrap {
      + .category_wrap {
        margin-top: 90px;
        padding-top: 50px;
        border-top: 1px solid #E7EBF0;
      }
    }
    .service_list {
      display: flex;
      margin-inline: -12px;
      justify-content: center;
      row-gap: 30px;
      margin-top: 50px;
      li {
        width: 25%;
        padding-inline: 12px;
        box-sizing: border-box;
        a {
          figure {
            aspect-ratio: 1 / 1;
            border-radius: 5px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: .3s;
            }
          }
          p {
            margin-top: 23px;
            line-height: 1.2;
            font-weight: 700;
            text-align: center;
            transition: .3s;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
            p {
              color: $thema-bl;
            }
          }
        }
      }
    }
    .block-contact {
      display: none;
    }

    @include sp {
      .rwd002-block-content {
        &__inner {
          grid-template-columns: repeat(2, 90px);
          a.cate {
            font-size: 12px;
            height: 28px;
          }
        }
      }
      .rwd002-list-content {
        margin-bottom: 0;
      }
      .category_wrap {
        + .category_wrap {
          margin-top: 47px;
          padding-top: 40px;
          border-top: 1px solid #E7EBF0;
        }
      }
      .service_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-inline: 0;
        gap: 20px 15px;
        margin-top: 28px;
        li {
          width: 100%;
          padding-inline: 0;
          a {
            p {
              margin-top: 10px;
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  &.is--detail {
    .rwd002-detail {
      padding: 0;
      &__entry,
      &__foot,
      &__head {
        max-width: none;
      }
    }
    .rwd002-block-freearea {
      padding: 0;
      margin: 0;
    }

    .rwd002-detail__head {
      max-width: calc(960px + 4rem);
      padding-inline: 2rem;
      margin-inline: auto;
      position: relative;
      min-height: 445px;
      display: grid;
      grid-template-columns: min(46%, 390px);
      align-items: center;
      place-content: center;
      justify-content: flex-start;
      .rwd002-detail__category {
        display: grid;
        place-content: center;
        width: 100px;
        min-width: 0;
        height: 30px;
        border-radius: 15px;
        border: 2px solid $font-color;
        box-sizing: border-box;
        color: $font-color;
        margin-bottom: 27px;
        padding: 0;
      }
      .rwd002-detail__title {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 25px;
        padding-bottom: 0;
        padding-left: 18px;
        border: none;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $thema-gr;
          left: 0;
          top: 13px;
        }
      }
      .rwd002-detail__image {
        border-radius: 5px;
        overflow: hidden;
        margin: 0;
        a {
          pointer-events: none;
        }
        @include pc {
          position: absolute;
          right: calc(50% - 50vw);
          top: 0;
          width: 50vw;
          height: 100%;
          .rwd002-detail__image-inner {
            width: 100%;
          }
          a {
            display: block;
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .rwd002-detail__desc {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.8;
      }
    }

    .block-lead {
      margin-top: 60px;
      dl {
        display: grid;
        grid-template-columns: 200px 1fr;
        align-items: center;
        padding: 50px 60px;
        box-sizing: border-box;
        border: 2px solid #EFEFEF;
        border-radius: 5px;
        dt {
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          color: $thema-gr;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 10px;
          li {
            padding-left: 20px;
            line-height: 1.2;
            font-size: 13px;
            position: relative;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: $thema-gr;
              left: 0;
              top: 3px;
            }
          }
        }
      }
    }

    .block-feature {
      padding-block: 90px 86px;
      margin-top: 94px;
      background-color: $thema-gr;
      border-radius: 10px;
      overflow: hidden;
      .line_ttl {
        color: #fff;
        &::before {
          background-color: #fff;
        }
      }
      ul {
        margin-top: 60px;
        li {
          display: grid;
          grid-template-columns: 50% 42%;
          justify-content: space-between;
          align-items: center;
          + li {
            margin-top: 26px;
          }
          figure {
            border-radius: 5px;
            overflow: hidden;
          }
          dl {
            dt {
              color: #fff;
              font-size: 24px;
              font-weight: 700;
              line-height: 1.2;
            }
            dd {
              color: #fff;
              font-size: 14px;
              line-height: 1.9;
              margin-top: 20px;
            }
          }
        }
      }
    }

    .block-service {
      padding-top: 105px;
      h4 {
        line-height: 1;
        font-size: 20px;
        font-weight: 700;
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          width: 37px;
          height: 1px;
          background-color: $thema-gr;
          bottom: 4px;
          margin-right: 16px;
        }
      }

      .cost_wrap {
        margin-top: 50px;
        .scroll_wrap {
          margin-top: 30px;
        }
        table {
          width: 100%;
          tr {
            th, td {
              border: 1px solid #C3C4C4;
              font-weight: 700;
              box-sizing: border-box;
              line-height: 1.2;
              text-align: center;
            }
            th {
              background-color: #485F84;
              color: #fff;
              font-size: 17px;
              padding: 17px 10px;
            }
            td {
              font-size: 15px;
              padding: 13px 10px;
            }
            &:nth-of-type(2n + 1) td {
              background-color: #F4F6F9;
            }
          }
        }
        .ex {
          margin-top: 15px;
          line-height: 1;
          text-align: right;
          font-size: 12px;
        }
      }

      .part_wrap {
        margin-top: 50px;
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 30px 23px;
          margin-top: 40px;
          li {
            figcaption {
              margin-top: 20px;
              font-size: 14px;
              font-weight: 700;
              line-height: 1.2;
              text-align: center;
            }
          }
        }
      }
    }

    .block-flow {
      margin-top: 160px;
      ol {
        margin-top: 38px;
        counter-reset: num 0;
        li {
          padding: 25px 25px;
          box-sizing: border-box;
          counter-increment: num 1;
          &:nth-of-type(2n + 1){
            background-color: #F4F6F9;
          }
        }
        dl {
          max-width: 780px;
          margin: auto;
          dt {
            padding-left: 40px;
            position: relative;
            font-size: 19px;
            font-weight: 700;
            line-height: 1.2;
            &::before {
              content: '0' counter(num);
              position: absolute;
              left: 0;
              top: 0;
              font-size: 22px;
              @include Work(500);
              line-height: 1;
              color: #485F84;
            }
          }
          dd {
            margin-top: 10px;
            font-size: 14px;
            line-height: 2;
          }
        }    
      }
    }

    .block-contact {
      position: relative;
      z-index: 1;
      .container {
        max-width: calc(1100px + 4rem);
      }
      .wh_wrap {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 280px;
        padding-inline: 70px 77px;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        border-radius: 5px;
        margin: auto;
      }
      .ttl_wrap {
        dt, dd {
          color: $thema-gr;
          line-height: 1;
        }
        dt {
          font-size: 48px;
          @include Work(600);
        }
        dd {
          font-size: 13px;
          font-weight: 700;
          margin-top: 16px;
        }
      }
      .tel_wrap {
        padding-left: 30px;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 22px;
          height: 22px;
          background: url(/assets/img/common/icon_tel.svg) no-repeat 0 / contain;
          left: 0;
          top: 6px;
        }
        dt {
          line-height: 1;
          a {
            font-size: 32px;
            @include Work(600);
            color: $thema-bl;
          }
        }
        dd {
          font-size: 10px;
          color: $thema-bl;
          line-height: 1;
          margin-top: 8px;
        }
      }
      .btn_wrap {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          width: 300px;
          height: 80px;
          border-radius: 40px;
          border: 1px solid $thema-bl;
          padding-right: 10px;
          box-sizing: border-box;
          svg {
            width: 22px;
            rect, path {
              fill: none;
              stroke: #485f84;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.22px;
              transition: .3s;
            }
          }
          span {
            font-size: 18px;
            font-weight: 700;
            color: $thema-bl;
            transition: .3s;
          }
          &:hover {
            background-color: $thema-bl;
            svg {
              rect, path {
                stroke: #fff;
              }
            }
            span {
              color: #fff;
            }
          }
        }
      }
      @include max(1350px){
        .wh_wrap {
          max-width: 970px;
          padding-inline: 35px 35px;
        }
      }
      @include max(1160px){
        .wh_wrap {
          max-width: 720px;
          justify-content: center;
          place-content: center;
          gap: 30px 30px;
          .ttl_wrap {
            width: 100%;
            text-align: center;
          }
          .btn_wrap a {
            width: 260px;
            height: 60px;
          }
        }
      }
    }

    .service-list {
      max-width: 820px;
      margin: auto;
      &__title {
        text-align: center;
        line-height: 1;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: .1em;
      }
      &__inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 25px;
        margin-top: 50px;
        > div:nth-of-type(n + 4){
          display: none;
        }
      }
      a {
        figure {
          aspect-ratio: 1 / 1;
          border-radius: 5px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
          }
        }
        p {
          margin-top: 23px;
          line-height: 1.2;
          font-weight: 700;
          text-align: center;
          transition: .3s;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          p {
            color: $thema-bl;
          }
        }
      }
    }

    .rwd002-taglist {
      margin-top: 60px;
      &__cats {
        display: grid;
        grid-template-columns: repeat(4, 100px);
        gap: 10px 15px;
        justify-content: center;
        a {
          display: grid;
          place-content: center;
          width: 100%;
          height: 30px;
          border: 2px solid #373737;
          color: #373737;
          box-sizing: border-box;
          border-radius: 15px;
          margin: 0;
          font-size: 14px;
          font-weight: 700;
          &.current, &:hover {
            background-color: #373737;
            border-color: #373737;
            color: #fff;
          }
        }
      }
      &__tags {
        display: none;
      }
    }

    footer {
      @include pc {
        margin-top: 120px;
      }
    }

    @include sp {
      .rwd002-detail {
        margin: 0;
      }
      .rwd002-detail__head {
        padding-inline: 25px;
        min-height: unset;
        display: block;
        .rwd002-detail__category {
          width: 90px;
          height: 28px;
          border-radius: 14px;
          font-size: 12px;
          margin-bottom: 14px;
        }
        .rwd002-detail__title {
          font-size: 24px;
          &::before {
            top: 10px;
          }
        }
        .rwd002-detail__desc {
          margin-top: 25px;
          margin-bottom: 0;
        }
      }
  
      .block-lead {
        margin-top: 35px;
        dl {
          display: block;
          padding: 25px 23px;
          border-radius: 5px;
          margin-inline: -10px;
          dt {
            font-size: 18px;
            text-align: center;
          }
          dd {
            margin-top: 18px;
          }
          ul {
            display: block;
            li {
              + li {
                margin-top: 9px;
              }
            }
          }
        }
      }
  
      .block-feature {
        padding-block: 57px 60px;
        margin-top: 67px;
        ul {
          margin-top: 30px;
          li {
            display: block;
            + li {
              margin-top: 40px;
            }
            figure {
              border-radius: 3px;
            }
            dl {
              margin-top: 34px;
              dt {
                font-size: 19px;
              }
              dd {
                margin-top: 20px;
              }
            }
          }
        }
      }
  
      .block-service {
        padding-top: 77px;
        h4 {
          font-size: 20px;
          &::before {
            bottom: 7px;
          }
        }
        .cost_wrap {
          margin-top: 44px;
          .scroll {
            font-size: 15px;
            @include Work(400);
            color: $thema-gr;
            line-height: 1;
            width: 87px;
            margin: 15px 0 0 auto;
            position: relative;
            padding-bottom: 2px;
            animation: scroll_icon 2s ease-in-out infinite;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              height: 6px;
              background: url(/assets/img/common/table_scroll.svg) no-repeat 0 / contain;
              left: 0;
              bottom: 0;
            }
          }
          @keyframes scroll_icon {
            0% {
              transform: translateX(0);
            }
            20% {
              transform: translateX(15px);
            }
            40% {
              transform: translateX(0);
            }
          }
          .scroll_wrap {
            margin-top: 18px;
            margin-right: -25px;
            overflow: auto;
          }
          table {
            width: 100%;
            tr {
              th, td {
                min-width: 320px;
                white-space: nowrap;
              }
            }
          }
        }
  
        .part_wrap {
          margin-top: 56px;
          ul {
            display: block;
            margin-top: 30px;
            li {
              + li {
                margin-top: 18px;
              }
              figcaption {
                margin-top: 16px;
              }
            }
          }
        }
      }
  
      .block-flow {
        margin-top: 50px;
        ol {
          margin-top: 34px;
          li {
            padding: 20px 27px;
            display: grid;
            place-content: center;
            min-height: 226px;
          }
          dl {
            dt {
              padding-left: 40px;
            }
            dd {
              margin-top: 10px;
              line-height: 1.7;
            }
          }    
        }
      }
  
      .block-contact {
        .wh_wrap {
          display: block;
          height: auto;
          padding: 45px 30px;
        }
        .ttl_wrap {
          dt {
            font-size: 36px;
          }
          dd {
            font-size: 11px;
            margin-top: 7px;
          }
        }
        .tel_wrap {
          margin: 25px auto 0;
          max-inline-size: max-content;
          dt a {
            letter-spacing: .02em;
          }
          dd {
            margin-top: 3px;
            text-align: center;
          }
        }
        .btn_wrap {
          margin-top: 20px;
          a {
            width: min(100%, 330px);
            height: 60px;
            border-radius: 30px;
            padding-right: 8px;
            margin: auto;
          }
        }
      }

      .service-list {
        margin-inline: 15px;
        &__inner {
          grid-template-columns: repeat(2, 1fr);
          gap: 20px 15px;
          margin-top: 28px;
        }
        a {
          p {
            margin-top: 10px;
            font-size: 15px;
          }
        }
      }

      .rwd002-taglist {
        margin-top: 46px;
        &__cats {
          grid-template-columns: repeat(2, 90px);
          a {
            font-size: 12px;
            height: 28px;
          }
        }
      }
    }
  }
}