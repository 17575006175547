.rwd002-container {
  margin-top: map-get($spacers, 6);
  @include media-down(lg) {
    margin-top: map-get($spacers, 3);
  }
}

.is--contact, .is--privacy, .is--profile {
  .rwd002-container {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 0;
    }
  }
}