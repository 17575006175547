.is--works {

  --tag-bl: #6F9AC0;

  .rwd002-container {
    margin-block: 40px 100px;
    @include sp {
      margin-bottom: 0;
    }
  }

  .works-list {
    &__inner {
      max-width: 960px;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 70px 40px;
    }

    a {
      position: relative;
      display: block;
      figure {
        border-radius: 3px;
        overflow: hidden;
        aspect-ratio: 1 / 1;
        img {
          transition: .3s;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .text_wrap {
        margin-top: 15px;
        .cate {
          font-size: 11px;
          font-weight: 700;
          line-height: 1;
          &::before {
            content: '';
            display: inline-block;
            position: relative;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $font-color;
            margin-right: 6px;
            bottom: 2px;
          }
        }
        .ttl {
          font-size: 15px;
          font-weight: 700;
          line-height: 1.5;
          margin-top: 12px;
          transition: .3s;
        }
        .tag_wrap {
          margin-top: 18px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px 15px;
          li {
            line-height: 1;
            font-size: 11px;
            color: var(--tag-bl);
          }
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
        .ttl {
          color: $thema-bl;
        }
      }
    }

    @include sp {
      .works-list__inner {
        display: block;
        margin-inline: 25px;
        > div + div {
          margin-top: 37px;
        }
      }

      a {
        .text_wrap {
          margin-top: 15px;
          .ttl {
            font-size: 17px;
            margin-top: 10px;
          }
          .tag_wrap {
            margin-top: 14px;
            gap: 10px 15px;
          }
        }
      }
    }
  }

  .rwd002-taglist{
    &__cats {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px 15px;
      a {
        display: grid;
        place-content: center;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        box-sizing: border-box;
        border: 2px solid #373737;
        font-size: 14px;
        font-weight: 700;
        color: #373737;
        margin: 0;
        &:hover, &.current {
          background-color: #373737;
          border-color: #373737;
          color: #fff;
        }
      }
    }
    
    &__tags {
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-feature-settings: "palt";
      max-width: 660px;
      margin-inline: auto;
    }
  
    &__list {
      gap: 10px 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      a {
        display: block;
        line-height: 1;
        font-size: 11px;
        font-weight: 700;
        color: var(--tag-bl);
      }
    }

    @include sp {
      padding-inline: 25px;
      &__cats {
        display: grid;
        grid-template-columns: repeat(2,90px);
        a {
          width: 100%;
        }
      }
    
      &__catBtn {
        font-size: 14px;
        padding-block: 6px;
      }
    
      &__list {
        gap: 6px 14px;
        align-items: flex-start;
      }
    }
  }

  &.is--list {
    .rwd002-taglist{
      margin-top: 40px;
    }

    @include sp {
      .rwd002-container {
        margin-top: 35px;
      }
      .content-head-banner {
        + .rwd002-taglist {
          padding-top: 30px;
          &::before {
            width: 100%;
            background-color: #F2F2F2;
          }
        }
      }

      .rwd002-taglist{
        margin-top: 0;
      }
    }
  }

  &.is--detail {
    .rwd002-container {
      margin-top: 50px;
    }

    .rwd002-detail {
      margin-top: 30px;
      &__category {
        display: grid;
        place-content: center;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        border: 2px solid $font-color;
        box-sizing: border-box;
        min-width: unset;
        font-size: 14px;
        font-weight: 700;
        color: $font-color;
        &:hover {
          background-color: $font-color;
          color: #fff;
        }
      }
      &__title {
        padding-bottom: 0;
        margin-bottom: 48px;
        border-bottom: none;
        line-height: 1.3;
        padding-left: 18px;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $thema-gr;
          left: 0;
          top: 13px;
        }
      }
      &__image {
        margin-bottom: 20px;
      }
      .tag_list {
        display: flex;
        gap: 20px 20px;
        margin-bottom: 45px;
        li {
          line-height: 1;
          a {
            font-size: 11px;
            font-weight: 700;
            color: #7E7E7E;
          }
        }
      }
      &__desc {
        font-size: 15px;
      }
      &__entry {
        margin-top: 70px;
        .editor-template {
          // .line_table {
          //   width: 100%;
          //   tr {
          //     display: flex;
          //     th, td {
          //       font-size: 14px;
          //       line-height: 1.3;
          //       box-sizing: border-box;
          //       display: block;
          //     }
          //     th {
          //       font-weight: 700;
          //       border-bottom: 1px solid #C0C1C1;
          //       min-width: 128px;
          //       padding: 16px 12px;
          //     }
          //     td {
          //       border-bottom: 1px solid #E9E9E9;
          //       padding: 16px 12px 16px 110px;
          //       flex: 1;
          //     }
          //   }
          // }
        }
      }
    }

    .connection-list {
      margin-bottom: 70px;
      &__title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        line-height: 1;
      }
      &__inner {
        margin-top: 50px;
        max-width: calc(1100px + 4rem);
        padding-inline: 2rem;
        column-gap: 55px;
        > div:nth-of-type(n + 4){
          display: none;
        }
      }

      + .rwd002-taglist {
        &__tags {
          display: none;
        }
      }
    }

    footer {
      @include pc {
        margin-top: 70px;
      }
    }

    @include sp {
      .rwd002-container {
        margin-top: 30px;
      }
  
      .rwd002-detail {
        margin-top: 0;
        padding-inline: 25px;
        &__category {
          width: 90px;
          height: 28px;
          border-radius: 14px;
          font-size: 12px;
          margin-bottom: 15px;
        }
        &__title {
          margin-bottom: 22px;
          line-height: 1.5;
          padding-left: 18px;
          font-size: 24px;
          &::before {
            top: 15px;
          }
        }
        &__image {
          margin-bottom: 12px;
        }
        .tag_list {
          gap: 15px;
          margin-bottom: 22px;
        }
        &__desc {
          font-size: 14px;
          line-height: 1.8;
          margin-bottom: 0;
        }
        &__entry {
          margin-top: 32px;
          .editor-template {
            .line_table {
              tr {
                padding-inline: 10px;
                &::after {
                  width: 128px;
                }
              }
            }
          }
        }
      }
  
      .connection-list {
        margin-bottom: 40px;
        &__inner {
          margin-top: 36px;
          padding-inline: 0;
        }
        + .rwd002-taglist {
          margin-bottom: 0;
        }
      }
    }
  }
}