.is--company {
  --bg-gr: #F4F6F9;

  .rwd002-detail {
    padding: 0;
    &__entry {
      max-width: unset;
    }
  }

  .page_link {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    justify-content: center;
    column-gap: 20px;
    .link_btn {
      border: 2px solid $thema-gr;
      background-color: #fff;
      color: $thema-gr;
      width: 100%;
      &::before, &::after {
        background-color: $thema-gr;
      }
      &.current, &:hover {
        background-color: $thema-gr;
        color: #fff;
        &::before, &::after {
          background-color: #fff;
        }
      }
    }
  }

  .anchor_list {
    display: flex;
    justify-content: center;
    column-gap: 45px;
    li {
      width: 140px;
      a {
        display: block;
        position: relative;
        font-size: 13px;
        color: #4D535F;
        line-height: 1;
        padding-left: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #4D535F;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $thema-gr;
          left: 0;
          top: 4px;
        }
        &:hover {
          color: $thema-gr;
          border-color: $thema-gr;
        }
      }
    }
  }

  .img_ttl {
    position: relative;
    .page_link {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      bottom: 6px;
    }
  }

  .sec_ttl {
    strong, small {
      display: block;
      line-height: 1;
    }
    strong {
      font-size: 28px;
      font-weight: 700;
    }
    small {
      font-size: 12px;
      @include Work(600);
      color: $thema-gr;
      margin-top: 10px;
    }
    @include pc {
      padding-left: 18px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $thema-gr;
        left: 0;
        top: 10px;
      }
    }
  }

  &.is--article-1 {
    .img_ttl .ttl_wrap {
      background-image: url(/assets/img/company/message/main@2x.jpg);
    }

    .block-message {
      padding-block: 80px 100px;
      .main_img {
        margin-top: 35px;
        border-radius: 5px;
        overflow: hidden;
        display: block;
      }
      .grid_wrap {
        display: grid;
        margin-top: 55px;
        .text {
          font-size: 15px;
          line-height: 2.2;
          letter-spacing: .1em;
        }
        .name {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 20px;
          dt {
            font-size: 12px;
            line-height: 1;
          }
          dd {
            margin-top: 15px;
            width: 128px;
          }
        }
        .img_wrap {
          position: relative;
          z-index: 1;
          picture {
            display: block;
            border-radius: 5px;
            overflow: hidden;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: $thema-gr;
            left: 20px;
            top: 20px;
            z-index: -1;
          }
        }
        @include pc {
          display: grid;
          grid-template-columns: 1fr 254px;
          column-gap: 50px;
          .name {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }
          .img_wrap {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
          }
        }
      }
    }

    .block-philosophy {
      padding-block: 75px 100px;
      background-color: var(--bg-gr);
      p {
        width: 514px;
        margin: 45px auto 0;
      }
    }

    .block-policy {
      padding-block: 80px;
      .list {
        margin-top: 50px;
        dl {
          + dl {
            margin-top: 25px;
          }
          dt {
            line-height: 1;
            color: $thema-gr;
            font-size: 18px;
            font-weight: 700;
            &::before {
              content: '-';
            }
          }
          dd {
            margin-top: 10px;
            font-size: 15px;
            line-height: 1.5;
          }
        }
      }
    }

    .block-action {
      padding-block: 70px 80px;
      background-color: var(--bg-gr);
      margin-bottom: 60px;
      ol {
        margin-top: 40px;
        counter-reset: num 0;
        li {
          counter-increment: num 1;
          padding-left: 20px;
          position: relative;
          font-size: 15px;
          + li {
            margin-top: 8px;
          }
          &::before {
            content: counter(num);
            position: absolute;
            left: 0;
            top: 0;
            color: $thema-gr;
            font-weight: 700;
          }
        }
      }
    }
  }

  &.is--article-2 {
    .img_ttl .ttl_wrap {
      background-image: url(/assets/img/company/profile/main@2x.jpg);
    }

    .block-info {
      padding-block: 97px 100px;
      @include pc {
        .container {
          display: grid;
          grid-template-columns: 270px 1fr;
        }
        .table_wrap {
          margin-top: -26px;
        }
      }
      .area_wrap {
        margin-top: 35px;
        grid-column: 2 / 3;
        figure {
          max-width: 446px;
          margin: auto;
        }
      }
      .map_wrap {
        .flame_wrap {
          margin-top: 53px;
          height: 400px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
        .text_wrap {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 14px;
            line-height: 2;
          }
          .btn_wrap {
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 160px;
              height: 34px;
              border-radius: 17px;
              background-color: #E7EBF0;
              span {
                font-size: 12px;
                @include Work(600);
                color: rgba(43, 52, 77, .7);
                &::before {
                  content: "\f3c5";
                  font-size: 11px;
                  font-family: Font Awesome\ 6 Free;
                  font-weight: 900;
                  margin-right: 5px;
                }
              }
              &:hover {
                background-color: $thema-bl;
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .chara_wrap {
        margin-top: 100px;
        h4 {
          max-width: 356px;
          margin: auto;
        }
        .character {
          margin: 40px auto 0;
          max-width: 570px;
          display: block;
        }
      }
    }

    .block-history {
      padding-block: 126px 100px;
      background-color: var(--bg-gr);
      .table_wrap tr {
        th {
          color: $thema-gr;
        }
        td {
          font-weight: 700;
        }
      }
      @include pc {
        .container {
          display: grid;
          grid-template-columns: 270px 1fr;
        }
        .table_wrap {
          margin-top: -26px;
        }
      }
    }

    .block-sdgs {
      padding-block: 140px 105px;
      .container {
        max-width: calc(740px + 4rem);
      }
      .lead_wrap {
        h3 {
          max-width: 540px;
          margin: auto;
        }
        .lead {
          margin-top: 45px;
          font-size: 17px;
          font-weight: 700;
          text-align: center;
          line-height: 1.8;
        }
        figure {
          max-width: 604px;
          margin: 44px auto 0;
        }
        .text {
          margin-top: 40px;
          font-size: 15px;
          line-height: 2;
        }
      }

      .list {
        margin-top: 70px;
        .wrap {
          + .wrap {
            margin-top: 70px;
          }

          h4 {
            text-align: center;
            line-height: 1;
          }
          figure {
            margin: 35px auto 0;
            &.icon3 {
              max-width: 308px;
            }
            &.icon4 {
              max-width: 410px;
            }
          }
          p {
            margin-top: 22px;
          }
        }
      }
    }
  }

  @include sp {
    .rwd002-detail {
      margin-block: 70px 0;
    }

    .page_link {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 6px;
      margin-inline: auto;
      padding-inline: 10px;
      max-width: 320px;
    }
  
    .anchor_list {
      column-gap: 20px;
      li {
        width: 92px;
      }
    }
  
    .img_ttl {
      .page_link {
        bottom: -24px;
      }
    }
  
    .sec_ttl {
      text-align: center;
      strong {
        font-size: 26px;
      }
      small {
        font-size: 10px;
        margin-top: 10px;
      }
    }
  
    &.is--article-1 {
      .img_ttl .ttl_wrap {
        background-image: url(/assets/img/company/message/main_sp@2x.jpg);
        @media(min-width:720px){
          background-image: url(/assets/img/company/message/main@2x.jpg);
          background-position: -460px 0;
        }
      }
  
      .block-message {
        padding-block: 56px 60px;
        .main_img {
          margin-top: 28px;
          border-radius: 5px;
          overflow: hidden;
          display: block;
        }
        .grid_wrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 28px;
          .text {
            line-height: 2;
            margin-bottom: 30px;
            width: 100%;
          }
          .name {
            margin-top: 0;
            width: 55%;
          }
          .img_wrap {
            width: 45%;
            picture {
              position: relative;
              right: 10px;
              bottom: 10px;
            }
            &::before {
              left: 0;
              top: 0;
            }
          }
        }
        @media(min-width:740px){
            padding-block: 80px 100px;
            .main_img {
              margin-top: 35px;
            }
            .grid_wrap {
              display: grid;
              grid-template-columns: 1fr 254px;
              column-gap: 50px;
              margin-top: 55px;
              .img_wrap {
                grid-column: 2 / 3;
                grid-row: 1 / 3;
              }
              .text {
                line-height: 2.2;
                margin-bottom: 0;
              }
              .name {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 20px;
              }
              .img_wrap {
                width: 100%;
                picture {
                  right: 20px;
                  bottom: 20px;
                }
                &::before {
                  left: 0;
                  top: 0;
                }
              }
            }
          
        }
      }
  
      .block-philosophy {
        padding-block: 60px 66px;
        p {
          width: min(100%, 284px);
          margin-top: 36px;
        }
      }
  
      .block-policy {
        padding-block: 57px;
        .list {
          margin-top: 35px;
          dl {
            dd {
              line-height: 1.7;
            }
          }
        }
      }
  
      .block-action {
        padding-block: 53px 60px;
        margin-bottom: 40px;
        ol {
          margin-top: 33px;
          li {
            padding-left: 20px;
            line-height: 1.7;
            + li {
              margin-top: 24px;
            }
          }
        }
      }
    }
  
    &.is--article-2 {
      .img_ttl .ttl_wrap {
        background-image: url(/assets/img/company/profile/main_sp@2x.jpg);
      }
  
      .block-info {
        padding-block: 56px 60px;
        .table_wrap {
          margin-top: 28px;
        }
        .area_wrap {
          margin-top: 25px;
        }
        .map_wrap {
          margin-top: 55px;
          .flame_wrap {
            margin: 0 -25px 0;
            height: 240px;
          }
          .text_wrap {
            margin: 25px auto 0;
            display: block;
            max-inline-size: max-content;
            p {
              font-size: 14px;
              line-height: 2;
            }
            .btn_wrap {
              margin-top: 20px;
              a {
                margin: auto;
                .fa-location-dot {
                  margin-right: 5px;
                  position: relative;
                  bottom: 2px;
                  &:before {
                    color: rgba(43, 52, 77, .7);
                    content: "\f3c5";
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
  
        .chara_wrap {
          margin: 88px 34px 0;
          .character {
            margin-top: 12px;
          }
        }
      }
  
      .block-history {
        padding-block: 56px 60px;
        .table_wrap {
          margin-top: 6px;
        }
      }
  
      .block-sdgs {
        padding-block: 88px 60px;
        .lead_wrap {
          .lead {
            margin-top: 30px;
            font-size: 15px;
            line-height: 1.7;
            text-align: center;
          }
          figure {
            margin-top: 28px;
          }
          .text {
            margin-top: 35px;
          }
        }
  
        .list {
          margin-top: 60px;
          .wrap {
            + .wrap {
              margin-top: 60px;
            }
  
            h4 {
              text-align: center;
              line-height: 1;
            }
            figure {
              margin-top: 27px;
              &.icon3 {
                max-width: 75%;
              }
              &.icon4 {
                max-width: 100%;
              }
            }
            p {
              margin-top: 22px;
              font-size: 15px;
              line-height: 2;
            }
          }
        }
      }
    }
  }
}