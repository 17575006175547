.is--home {
  .sec_ttl {
    strong,
    small {
      display: block;
      line-height: 1;
      color: $thema-gr;
    }
    strong {
      font-size: 60px;
      @include Work(600);
      letter-spacing: 0.12em;
    }
    small {
      font-size: 13px;
      font-weight: 700;
      margin-top: 15px;
      letter-spacing: 0.18em;
    }
  }
  .container {
    max-width: 1200px;
    padding-inline: 50px;
  }
  .rwd002-container {
    margin-top: 0;
  }

  @keyframes mvScroll {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .mainvisual {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    z-index: 1;
    .main_wrap {
      position: relative;
      height: 100%;
      padding-inline: 30px;
      box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden;
      .slide_wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: calc(100% - 60px);
        height: 100%;
        z-index: -1;
        border-radius: 5px;
        overflow: hidden;
      }

      .swiper-pagination {
        right: 13px;
        .swiper-pagination-bullet {
          background-color: #cfd4de;
          width: 2px;
          height: 16px;
          border-radius: unset;
          margin: 0;
          opacity: 1;
          + .swiper-pagination-bullet {
            margin-top: 3px;
          }
          &.swiper-pagination-bullet-active {
            background-color: $thema-gr;
          }
        }
      }

      .text_wrap {
        max-width: 385px;
        position: absolute;
        left: 80px;
        bottom: 28%;
        opacity: 0;
        .since {
          font-size: 12px;
          @include Work(600);
          color: #fff;
          line-height: 1;
          letter-spacing: 0.18em;
          &::before {
            content: "";
            display: inline-block;
            position: relative;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #fff;
            bottom: 1px;
            margin-right: 10px;
          }
        }
        h2 {
          margin-top: 23px;
        }
        .text {
          margin-top: 18px;
          color: #fff;
          font-size: 11px;
          font-weight: 700;
          line-height: 2;
          letter-spacing: 0.14em;
        }
      }
    }
    .mv_slide {
      height: 100%;
      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-slide-active img,
      .swiper-slide-duplicate-active img,
      .swiper-slide-prev img {
        transform: scale(1.1);
        animation: zoomOut 8.4s linear 0s 1 normal both;
      }
    }

    .news_wrap {
      width: 640px;
      height: 60px;
      position: absolute;
      right: 30px;
      bottom: 0;
      a {
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        display: grid;
        grid-template-columns: 86px 75px 1fr;
        align-items: center;
        padding-inline: 30px 40px;
        box-sizing: border-box;
        background-color: #f4f6f9;
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
          background-color: $thema-gr;
        }
        &::before {
          width: 18px;
          height: 18px;
          opacity: 0.3;
          right: 10px;
          transition: 0.3s;
        }
        &::after {
          width: 6px;
          height: 6px;
          right: 16px;
        }
        .date {
          font-size: 12px;
          @include Work(500);
        }
        .cate {
          font-size: 12px;
          font-weight: 700;
          color: $thema-gr;
        }
        .ttl {
          font-size: 13px;
          font-weight: 700;
          max-inline-size: max-content;
          background: linear-gradient($font-color, $font-color) no-repeat left bottom / 100% 0px;
          transition: 0.3s;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover {
          &::before {
            transform: scale(1.3);
          }
          .ttl {
            background-size: 100% 1px;
          }
        }
      }
    }
    .scroll {
      width: 180px;
      font-size: 10px;
      @include Work(600);
      letter-spacing: 0.15em;
      color: $thema-gr;
      line-height: 1;
      position: absolute;
      left: 20px;
      bottom: 170px;
      transform-origin: left top;
      transform: rotate(90deg);
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 93px;
        height: 1px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::before {
        background-color: #c4cbd8;
      }
      &::after {
        background-color: $thema-gr;
        animation: mvScroll 1.5s ease-in-out infinite alternate;
        transform-origin: left top;
      }
    }
    &.loaded .main_wrap .text_wrap {
      opacity: 1;
      transition: 2s 0.3s;
    }
  }

  @keyframes zoomOut {
    from {
      transform: scale(1.1);
    }
    to {
      transform: scale(1);
    }
  }

  .block-strength {
    padding-block: 110px 210px;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: calc(100% + 450px);
      background-color: #f4f6f9;
      left: 0;
      top: -207px;
      z-index: -1;
    }
    .container {
      max-width: 1380px;
      display: grid;
      grid-template-columns: 50% 1fr;
      align-items: center;
      column-gap: 60px;
    }
    picture {
      max-width: 647px;
    }
    .text_wrap {
      dl {
        margin-top: 35px;
        max-width: 445px;
        dt {
          font-size: 23px;
          font-weight: 700;
          line-height: 1.7;
          strong {
            color: $thema-gr;
          }
        }
        dd {
          margin-top: 30px;
          font-size: 15px;
          font-weight: 700;
          line-height: 2.2;
        }
      }
      .btn_wrap {
        margin-top: 45px;
      }
    }
    @include max(1100px) {
      .container {
        column-gap: 40px;
      }
      .text_wrap dl dt {
        font-size: 19px;
      }
    }
  }

  .block-service {
    .sec_ttl {
      text-align: center;
      strong,
      small {
        color: #fff;
      }
    }

    .link_list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      position: relative;
      margin-top: 35px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 60px);
        height: calc(100% + 254px);
        background-color: $thema-gr;
        left: 30px;
        bottom: -54px;
        z-index: -1;
      }

      li {
        a {
          display: block;
          position: relative;
          height: 510px;
          z-index: 1;
          display: grid;
          place-content: center;
          place-items: center;
          picture {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background-color: $thema-bl;
              opacity: 0;
              transition: 0.3s;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .line_ttl {
            color: #fff;
            small {
              color: #fff;
            }
          }
          p {
            color: #fff;
            font-size: 13px;
            font-weight: 700;
            line-height: 1;
            margin-top: 28px;
          }
          .btn_wrap {
            display: grid;
            place-content: center;
            width: 186px;
            height: 50px;
            border-radius: 25px;
            background-color: #fff;
            padding-right: 5px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 40px;
            margin: auto;
            span {
              font-size: 12px;
              @include Work(600);
              color: $thema-gr;
              transition: 0.3s;
            }
            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              border-radius: 50%;
              background-color: $thema-gr;
              transition: 0.3s;
            }
            &::before {
              width: 18px;
              height: 18px;
              right: 15px;
              opacity: 0.2;
            }
            &::after {
              width: 6px;
              height: 6px;
              right: 21px;
            }
          }
          &:hover {
            picture::before {
              opacity: 0.7;
            }
            .btn_wrap {
              &::before,
              &::after {
                background-color: $thema-bl;
              }
              span {
                color: $thema-bl;
              }
            }
          }
        }
      }
    }

    .bn_list {
      display: grid;
      grid-template-columns: repeat(2, 392px);
      justify-content: center;
      column-gap: 16px;
      margin-top: 120px;
      li {
        a {
          display: block;
          position: relative;
          picture {
            transition: 0.3s;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            transition: 0.3s;
          }
          &:hover {
            picture {
              opacity: 0;
            }
            &::before {
              opacity: 1;
            }
          }
        }
        &:nth-of-type(1) a::before {
          background: url(/assets/img/home/service_bn_01h@2x.jpg) no-repeat 0 / contain;
        }
        &:nth-of-type(2) a::before {
          background: url(/assets/img/home/service_bn_02h@2x.jpg) no-repeat 0 / contain;
        }
      }
    }
  }

  .block-works {
    margin-top: 90px;
    h2 {
      text-align: center;
    }
    .slide_wrap {
      margin-top: 55px;
      .swiper {
        .swiper-wrapper {
          .swiper-slide {
            width: 330px;
          }
        }
      }
      a {
        display: block;
        figure {
          aspect-ratio: 1 / 1;
          border-radius: 3px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }
        .text_wrap {
          margin-top: 17px;
          .cate {
            line-height: 1;
            font-size: 11px;
            font-weight: 700;
            color: #6f9ac0;
            &::before {
              content: "";
              display: inline-block;
              position: relative;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #6f9ac0;
              margin-right: 5px;
              bottom: 2px;
            }
          }
          .ttl {
            transition: 0.3s;
            margin-top: 10px;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          .text_wrap .ttl {
            color: $thema-bl;
          }
        }
      }
    }
    .btn_wrap {
      margin-top: 56px;
      a {
        margin: auto;
      }
    }
  }

  .block-news {
    margin-top: 90px;
    padding-top: 120px;
    background-color: #f4f6f9;
    .tag_list {
      li {
        line-height: 1;
        + li {
          margin-top: 18px;
        }
        a {
          font-size: 11px;
          font-weight: 700;
          color: $thema-gr;
          letter-spacing: 0.1em;
          padding-bottom: 9px;
          background: linear-gradient(#bec9db, #bec9db) no-repeat left bottom / 100% 0;
          display: block;
          width: 86px;
          &:hover {
            background-size: 100% 1px;
          }
          &::before {
            content: "";
            display: inline-block;
            position: relative;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #bec9db;
            margin-right: 13px;
            bottom: 1px;
          }
        }
        &.is-all a {
          background-size: 100% 1px;
        }
      }
    }
    .news_list {
      li {
        + li {
          border-top: 1px solid #d6dee8;
        }
      }
      a {
        display: grid;
        grid-template-columns: 160px 1fr;
        column-gap: 46px;
        align-items: center;
        padding: 20px 42px 20px 0;
        box-sizing: border-box;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 18px;
          background: url(/assets/img/common/arrow.svg) no-repeat 0 / contain;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s;
        }
        figure {
          border-radius: 3px;
          overflow: hidden;
          aspect-ratio: 1 / 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }
        .text_wrap {
          .info {
            display: flex;
            align-items: center;
            column-gap: 15px;
            line-height: 1;
            .date {
              font-size: 12px;
              @include Work(500);
            }
            .cate {
              font-size: 12px;
              font-weight: 700;
              color: $thema-gr;
            }
          }
          .ttl {
            margin-top: 22px;
            line-height: 1.5;
            span {
              font-size: 15px;
              font-weight: 700;
              background: linear-gradient($font-color, $font-color) no-repeat left bottom / 100% 0;
              transition: 0.3s;
            }
          }
        }
        &:hover {
          &::before {
            transform: translateX(5px);
          }
          img {
            transform: scale(1.1);
          }
          .text_wrap .ttl span {
            background-size: 100% 1px;
          }
        }
      }
    }

    @include pc {
      .container {
        display: grid;
        grid-template-columns: 320px 1fr;
      }
      h2 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      .tag_list {
        margin-top: 50px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        li {
          a {
          }
        }
      }
      .news_list {
        grid-column: 2 / 3;
        grid-row: 1 / 5;
      }
      .btn_wrap {
        margin-top: 40px;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }
    }
  }

  .block-company {
    padding-top: 210px;
    position: relative;
    background-color: #f4f6f9;
    .slide_wrap {
      width: calc(100% - 60px);
      height: 380px;
      position: absolute;
      left: 0;
      right: 0;
      top: 105px;
      margin: auto;
      z-index: 1;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(9, 20, 60, 0.5);
        z-index: 1;
      }
      .company_slide {
        height: 100%;
        .swiper-slide img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .swiper-slide-active img,
        .swiper-slide-duplicate-active img,
        .swiper-slide-prev img {
          transform: scale(1.1);
          animation: zoomOut 8.4s linear 0s 1 normal both;
        }
      }
    }

    .sec_ttl {
      position: relative;
      z-index: 1;
      strong,
      small {
        color: #fff;
      }
    }

    .link_list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      margin-top: 60px;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 263px;
        height: 175px;
        background: url(/assets/img/home/company_chara@2x.png) no-repeat 0 / contain;
        right: 50px;
        top: -124px;
        z-index: 1;
      }
      li {
        a {
          display: block;
          height: 390px;
          position: relative;
          border-radius: 3px;
          overflow: hidden;
          picture {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: 0.3s;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          h3 {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 50px;
            color: #fff;
            small {
              color: #fff;
            }
          }
          &:hover picture {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @keyframes imgCarousel {
    100% {
      transform: translateX(-3660px);
      @include sp {
        transform: translateX(-2260px);
      }
    }
  }

  .block-recruit {
    padding-block: 140px 115px;
    background-color: #f4f6f9;
    position: relative;
    @include pc {
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 350px;
        height: calc(100% + 466px);
        background-color: #fff;
        left: 0;
        bottom: 0;
        @include min(1200px) {
          width: calc(50% - 232px);
        }
      }
      .carousel_wrap {
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 50px;
          height: 100%;
          background-color: #f5f6f9;
          right: 0;
          top: 0;
          z-index: 1;
        }
      }
      @include min(1000px) {
        .sec_ttl strong {
          font-size: 77px;
        }
      }
    }
    .carousel_wrap {
      .container {
        padding-left: 0;
        margin-left: 0;
        max-width: calc(50% + 550px);
        overflow: hidden;
      }
      ul {
        display: flex;
        animation: imgCarousel 70s linear infinite;
        width: 7330px;
        column-gap: 10px;
        li {
          width: 3660px;
          picture {
            display: block;
          }
          img {
            width: 100%;
          }
        }
      }
    }

    .content_wrap {
      margin-top: 70px;
      .container {
        display: grid;
        grid-template-columns: 41% 1fr;
      }
      .sec_ttl {
        strong,
        small {
          color: #ff6b49;
        }
      }
      .right_wrap {
        dl {
          dt {
            font-size: 28px;
            font-weight: 700;
            line-height: 1.7;
          }
          dd {
            margin-top: 35px;
            font-size: 15px;
            font-weight: 700;
            line-height: 2.2;
            max-width: 430px;
          }
        }
        .btn_wrap {
          margin-top: 37px;
          .link_btn {
            background-color: #ff6b49;
            &:hover {
              background-color: $thema-bl;
            }
          }
        }
      }
    }
  }

  footer {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 150px;
      background-color: #f4f6f9;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  @include sp {
    .sec_ttl {
      strong {
        font-size: 40px;
      }
      small {
        font-size: 11px;
        margin-top: 5px;
      }
    }

    .container {
      padding-inline: 25px;
    }

    .mainvisual {
      height: calc(100svh - 70px);
      .main_wrap {
        position: relative;
        height: calc(100% - 90px);
        padding-inline: 25px;
        overflow: hidden;
        .slide_wrap {
          width: calc(100% - 50px);
          border-radius: 5px;
        }
        .swiper-pagination {
          right: 10px;
        }
        .text_wrap {
          left: 40px;
          bottom: 40px;
          .since {
            font-size: 11px;
            &::before {
              width: 5px;
              height: 5px;
              bottom: 0;
              margin-right: 7px;
            }
          }
          h2 {
            max-width: 266px;
            margin-top: 16px;
          }
          .text {
            margin-top: 14px;
            font-size: 10px;
            line-height: 1.7;
            letter-spacing: 0;
          }
        }
        @media (min-width: 720px) {
          .text_wrap {
            max-width: 470px;
            .since {
              font-size: 15px;
            }
            h2 {
              max-width: 100%;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }

      .news_wrap {
        width: 100%;
        height: 90px;
        position: relative;
        right: 0;
        a {
          border-top-left-radius: unset;
          display: grid;
          grid-template-columns: 84px 1fr;
          align-items: center;
          place-content: center;
          row-gap: 8px;
          padding-inline: 40px 64px;
          &::before {
            right: 31px;
          }
          &::after {
            right: 37px;
          }
          .date {
            font-size: 12px;
          }
          .cate {
            font-size: 12px;
          }
          .ttl {
            font-size: 13px;
            max-inline-size: max-content;
            grid-column: 1 / 3;
            grid-row: 2 / 3;
          }
        }
      }
      .scroll {
        left: 18px;
      }
    }

    .block-strength {
      padding-block: 57px 77px;
      .container {
        display: block;
      }
      .text_wrap {
        margin-top: 10px;
        dl {
          margin-top: 20px;
          max-width: 100%;
          dt {
            font-size: 18px;
            line-height: 1.8;
            @include max(380px) {
              font-size: 17px;
            }
          }
          dd {
            margin-top: 15px;
            line-height: 2;
          }
        }
        .btn_wrap {
          margin-top: 30px;
        }
      }
    }

    .block-service {
      padding-top: 50px;

      .link_list {
        display: block;
        margin-top: 35px;
        padding: 0 25px 68px;
        &::before {
          width: 100%;
          height: calc(100% + 140px);
          left: 0;
          bottom: 0;
        }

        li {
          + li {
            margin-top: 6px;
          }
          a {
            height: 150px;
            border-radius: 5px;
            overflow: hidden;
            p {
              margin-top: 14px;
            }
            .btn_wrap {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              padding-right: 0;
              left: auto;
              right: 13px;
              bottom: 13px;
              span {
                display: none;
              }
              &::before {
                display: none;
              }
              &::after {
                inset: 0;
                margin: auto;
              }
            }
          }
        }
      }

      .bn_list {
        display: block;
        margin-top: 74px;
        li {
          + li {
            margin-top: 20px;
          }
        }
        @media (min-width: 740px) {
          display: grid;
          grid-template-columns: repeat(2, auto);
          margin-top: 120px;
          li {
          +li{
            margin-top: 0;
          }
          }
        }
      }
    }

    .block-works {
      margin-top: 77px;
      .slide_wrap {
        margin-top: 33px;
        a {
          .text_wrap {
            margin-top: 12px;
            .cate {
              &::before {
                width: 4px;
                height: 4px;
                margin-right: 3px;
                bottom: 2px;
              }
            }
            .ttl {
              margin-top: 8px;
              font-size: 15px;
            }
          }
        }
      }
      .btn_wrap {
        margin-top: 34px;
      }
    }

    .block-news {
      margin-top: 77px;
      padding-top: 70px;
      .sec_ttl {
        text-align: center;
      }
      .tag_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 13px 10px;
        margin-top: 35px;
        li {
          line-height: 1;
          + li {
            margin-top: 0;
          }
          a {
            font-size: 11px;
            padding-bottom: 9px;
            width: 100%;
            &::before {
              width: 6px;
              height: 6px;
              margin-right: 13px;
              bottom: 1px;
            }
          }
        }
      }
      .news_list {
        margin-top: 20px;
        li {
          + li {
            border-top: 1px solid #d6dee8;
          }
        }
        a {
          display: grid;
          grid-template-columns: 78px 1fr;
          column-gap: 18px;
          padding: 20px 34px 20px 0;
          &::before {
            width: 11px;
            height: 10px;
          }
          figure {
            border-radius: 2px;
          }
          .text_wrap {
            .info {
              column-gap: 17px;
            }
            .ttl {
              margin-top: 8px;
              line-height: 1.4;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      .btn_wrap {
        margin-top: 18px;
      }
    }

    .block-company {
      padding-top: 190px;
      .sec_ttl {
        text-align: center;
      }
      .slide_wrap {
        width: 100%;
        height: 390px;
        top: 76px;
      }

      .link_list {
        display: block;
        margin-top: 34px;
        &::before {
          width: 116px;
          height: 55px;
          right: -4px;
          top: -44px;
        }
        li {
          + li {
            margin-top: 9px;
          }
          a {
            height: 233px;
            border-radius: 2px;
            h3 {
              bottom: 25px;
            }
          }
        }
        @media(min-width:720px){
          &::before{
            right: 50px;
          }
          li{
            aspect-ratio: 535/390;
            max-width: 90%;
            margin: 0 auto;
            a{
              height: 100%;
              border-radius: 3px;
              h3{
                bottom: 50px;
              }
            }
          }
        }
      }
    }

    .block-recruit {
      padding-block: 87px 0;
      .carousel_wrap {
        ul {
          width: 4520px;
          column-gap: 4px;
          li {
            width: 2258px;
          }
        }
      }
      .sec_ttl {
        text-align: center;
      }
      .content_wrap {
        margin-top: 38px;
        .container {
          display: block;
        }
        .right_wrap {
          dl {
            margin-top: 16px;
            dt {
              font-size: 19px;
              line-height: 1.8;
            }
            dd {
              margin-top: 18px;
              line-height: 2;
              max-width: 100%;
            }
          }
          .btn_wrap {
            margin-top: 28px;
          }
        }
      }
    }

    footer {
      background-color: #f4f6f9;
      &::before {
        display: none;
      }
    }
  }
}
