body {
  color: $font-color;

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-weight: 500;
    color: $font-color;
    letter-spacing: .05em;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .3s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

a.tel {
  @include pc {
    pointer-events: none;
  }
}

.container {
  max-width: calc(960px + 4rem);
  margin-inline: auto;
  padding-inline: 2rem;
  @include sp {
    padding-inline: 25px;
  }
}

.line_ttl {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  padding-bottom: 18px;
  position: relative;
  letter-spacing: .16em;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 36px;
    height: 3px;
    background-color: $thema-gr;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  small {
    display: block;
    line-height: 1;
    font-size: 10px;
    @include Work(600);
    margin-bottom: 12px;
  }
  @include sp {
    font-size: 20px;
    padding-bottom: 16px;
    &::before {
      height: 2px;
    }
  }
}

.link_btn {
  display: grid;
  place-content: center;
  width: 186px;
  height: 50px;
  border-radius: 25px;
  background-color: $thema-gr;
  padding-right: 5px;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  font-size: 12px;
  @include Work(600);
  &:hover {
    background-color: $thema-bl;
    color: #fff;
  }
  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background-color: #fff;
  }
  &::before {
    width: 18px;
    height: 18px;
    opacity: .2;
    right: 15px;
  }
  &::after {
    width: 6px;
    height: 6px;
    right: 21px;
  }
  @include sp {
    margin: auto;
  }
}

.content-head-banner {
  .ttl_wrap {
    display: flex;
    align-items: center;
    h2 {
      strong, small {
        display: block;
        line-height: 1;
      }
      strong {
        font-size: 55px;
        @include Work(600);
        letter-spacing: .12em;
      }
      small {
        font-size: 13px;
        font-weight: 700;
        margin-top: 13px;
        letter-spacing: .1em;
      }
    }
  }

  .pan_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    margin-inline: 10.36vw;
    li {
      font-size: 10px;
      @include Work(600);
      color: $thema-gr;
      line-height: 1;
      a {
        color: $thema-gr;
      }
      &:not(:last-of-type){
        white-space: nowrap;
        &::after {
          content: '';
          margin-inline: 5px;
          display: inline-block;
          width: 10px;
          height: 1px;
          background-color: #C3C4C4;
          position: relative;
          bottom: 4px;
        }
      }
      &:first-of-type a {
        color: #C3C4C4;
      }
    }
  }

  &.img_ttl {
    margin-inline: 30px;
    .ttl_wrap {
      padding-left: 60px;
      box-sizing: border-box;
      height: 366px;
      align-items: center;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: cover;
      h2 {
        strong, small {
          color: #fff;
        }
      }
    }
    .pan_wrap {
      margin-top: 20px;
      margin-right: 0;
    }
  }

  &.text_ttl {
    position: relative;
    padding-bottom: 26px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 100px);
      height: 1px;
      background-color: #E7EBF0;
      right: 26px;
      bottom: 0;
    }
    .ttl_wrap {
      justify-content: center;
      margin-top: 67px;
      h2 {
        text-align: center;
        strong, small {
          color: $thema-gr;
        }
      }
    }
    .pan_wrap {
      margin-top: 20px;
      margin-right: 38px;
    }
  }

  @include sp {
    .ttl_wrap {
      display: flex;
      align-items: center;
      h2 {
        strong {
          font-size: 40px;
        }
        small {
          font-size: 11px;
          margin-top: 8px;
        }
        @media(min-width:720px){
          strong{
            font-size: 55px;
          }
          small{
            font-size: 13px;
            margin-top: 13px;
          }
        }
      }
    }
    &.text_ttl {
      &::before {
        width: calc(100% - 50px);
        right: 25px;
      }
      .ttl_wrap {
        margin-top: 40px;
      }
    }
    &.img_ttl {
      margin-inline: 18px;
      .ttl_wrap {
        padding-left: 30px;
        padding-bottom: 48px;
        height: 384px;
        align-items: flex-end;
        background-position: center;
        @media(min-width:720px){
          height: 366px;
          align-items: center;
          padding-bottom: 0;
          padding-left: 60px;
        }
      }
    }
    .pan_wrap {
      display: none;
    }
  }
}

.line_table {
  width: 100%;
  tr {
    display: flex;
    position: relative;
    align-items: center;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
    }
    &::before {
      width: 100%;
      background-color: #E9E9E9;
    }
    &::after {
      width: 128px;
      background-color: #C0C1C1;
    }
    th, td {
      font-size: 14px;
      box-sizing: border-box;
      display: block;
    }
    th {
      font-weight: 700;
      width: 170px;
      padding: 0 12px;
      line-height: 1.8;
    }
    td {
      padding: 21px 12px;
      flex: 1;
      line-height: 2;
      a {
        color: $font-color;
      }
    }
  }
  
  @include sp {
    tr {
      display: block;
      padding: 15px 0 12px;
      &::after {
        width: 77px;
      }
      th, td {
        padding: 0;
      }
      th {
        min-width: unset;
        line-height: 1;
        width: 100%;
      }
      td {
        margin-top: 6px;
        line-height: 1.6;
      }
    }
  }
}

.rwd002-moduletitle__inner::after {
  background: $font-color;
}

.btn-primary, .is-block-6.is-catlist.rwd002-block-content .cate.current, .rwd002-block-banner a.is-btn, .rwd002-comment__form-btn, .rwd002-detail__edit-btn, .rwd002-form-index__button, .rwd002-form-input__submit {
  background-color: $font-color;
  border: none;
  &:hover, &:active, &:focus, &:disabled {
    background-color: #444 !important;
  }
}