footer {
  position: relative;
  .container {
    @include pc {
      max-width: 1200px;
      padding-inline: 50px;
    }
  }

  .contact_wrap {
    position: relative;
    z-index: 1;
    margin-bottom: -140px;
    .wh_wrap {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: 280px;
      padding-inline: 70px 77px;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border-radius: 5px;
      margin: auto;
    }
    .ttl_wrap {
      dt, dd {
        color: $thema-gr;
        line-height: 1;
      }
      dt {
        font-size: 48px;
        @include Work(600);
        @include pc {
          line-height: 33px;
        }
      }
      dd {
        font-size: 13px;
        font-weight: 700;
        margin-top: 16px;
      }
    }
    .tel_wrap {
      padding-left: 30px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        background: url(/assets/img/common/icon_tel.svg) no-repeat 0 / contain;
        left: 0;
        top: 6px;
        @include pc {
          top: -1px;
        }
      }
      dt {
        line-height: 1;
        @include pc {
          line-height: 19px;
        }
        a {
          font-size: 32px;
          @include Work(500);
          color: #364874;
        }
      }
      dd {
        font-size: 12px;
        color: #364874;
        line-height: 1;
        margin-top: 6px;
      }
    }
    .btn_wrap {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: 300px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid $thema-bl;
        padding-right: 10px;
        box-sizing: border-box;
        svg {
          width: 22px;
          rect, path {
            fill: none;
            stroke: #485f84;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.22px;
            transition: .3s;
          }
        }
        span {
          font-size: 18px;
          font-weight: 700;
          color: #364874;
          transition: .3s;
        }
        &:hover {
          background-color: #364874;
          svg {
            rect, path {
              stroke: #fff;
            }
          }
          span {
            color: #fff;
          }
        }
      }
    }
    @include max(1270px){
      .wh_wrap {
        max-width: 970px;
        padding-inline: 35px 35px;
      }
    }
    @include max(1160px){
      .wh_wrap {
        max-width: 720px;
        justify-content: center;
        place-content: center;
        gap: 30px 30px;
        .ttl_wrap {
          width: 100%;
          text-align: center;
        }
        .btn_wrap a {
          width: 260px;
          height: 60px;
        }
      }
    }
  }

  .main_wrap {
    background-color: $thema-gr;
    padding-block: 230px 25px;

    .top_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      column-gap: 50px;
      .left_wrap {
        display: grid;
        grid-template-columns: 132px 1fr;
        column-gap: 36px;
        .logo {
          a:hover {
            opacity: .7;
          }
        }
        .text_wrap {
          dl {
            dt {
              font-size: 20px;
              font-weight: 700;
              color: #fff;
              line-height: 1;
              margin-bottom: 20px;
            }
            dd {
              font-size: 11px;
              font-weight: 500;
              color: #fff;
              line-height: 1;
              letter-spacing: .21em;
              a {
                color: #fff;
                letter-spacing: .21em;
              }
              + dd {
                margin-top: 9px;
              }
            }
          }
          .map {
            margin-top: 26px;
            a {
              display: flex;
              align-items: center;
              width: 110px;
              border-bottom: 1px solid #fff;
              padding: 0 0 5px 2px;
              box-sizing: border-box;
              .fa-location-dot {
                line-height: 0;
                &::before {
                  content: "\f3c5";
                  color: #fff;
                  font-size: 11px;
                  transition: .3s;
                }
              }
              span {
                font-size: 12px;
                @include Work(500);
                color: #fff;
                margin-left: 7px;
                letter-spacing: .15em;
                transition: .3s;
              }
              &:hover {
                border-color: #FFF019;
                .fa-location-dot::before {
                  color: #FFF019;
                }
                span {
                  color: #FFF019;
                }
              }
            }
          }
        }
      }

      nav {
        ul {
          li {
            line-height: 1;
            a {
              color: #fff;
              background: linear-gradient(#fff, #fff) no-repeat left bottom / 100% 0;
              &:hover {
                background-size: 100% 1px;
              }
            }
          }
        }
        > ul {
          display: grid;
          > li {
            position: relative;
            padding-left: 12px;
            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #fff;
              margin-right: 7px;
              left: 0;
              top: 7px;
            }
            > a {
              font-size: 13px;
              font-weight: 700;
            }
          }
          @include pc {
            grid-template-columns: 170px 180px 130px;
            row-gap: 30px;
            > li {
              &:nth-of-type(2){
                order: 4;
              }
              &:nth-of-type(3){
                grid-column: 1 / 2;
                grid-row: 3 / 5;
              }
              &:nth-of-type(4){
                order: 2;
              }
              &:nth-of-type(5){
                grid-column: 2 / 3;
                grid-row: 2 / 4;
              }
              &:nth-of-type(6){
                order: 3;
              }
              &:nth-of-type(7){
                order: 7;
              }
            }
          }
          @include max(1020px){
            grid-template-columns: 140px 140px 80px;
          }
        }
        .sub_list {
          margin-top: 12px;
          li {
            + li {
              margin-top: 7px;
            }
            a {
              font-size: 11px;
              font-weight: 500;
              &::before {
                content: '-';
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .bottom_wrap {
      margin-top: 106px;
      padding-top: 20px;
      border-top: 1px solid rgba(234, 235, 235, .5);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .copy {
        font-size: 10px;
        @include Work(400);
        color: rgba(255, 255, 255, .6);
      }
      .right_wrap {
        display: flex;
        align-items: center;
        column-gap: 20px;
        ul {
          display: flex;
          align-items: center;
          li {
            font-size: 10px;
            @include Work(400);
            color: rgba(255, 255, 255, .6);
            + li::before {
              content: '/';
              margin-inline: 15px;
            }
            a {
              @include Work(400);
              color: rgba(255, 255, 255, .6);
              &:hover {
                opacity: .7;
              }
            }
          }
        }
        .login {
          width: 13px;
          a:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}

.pagetop {
  position: absolute;
  right: 46px;
  top: 50px;
  width: 54px;
  height: 56px;
  z-index: 1;
  @include max(1360px){
    right: 20px;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    font-size: 12px;
    @include Work(500);
    color: $thema-bl;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: $thema-bl;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 38px;
      height: 38px;
      border-top: 1px solid $thema-bl;
      border-right: 1px solid $thema-bl;
      left: 0;
      right: 0;
      top: 15px;
      margin: auto;
      transform: rotate(-45deg);
    }
    &:hover {
      transform: translateY(-8px);
    }
  }
}

@include pc {
  .is--news {
    footer {
      margin-top: 80px;
    }
  }
}

@include sp {
  footer {
    padding-top: 134px;
    .contact_wrap {
      margin-bottom: -200px;
      .wh_wrap {
        display: block;
        height: auto;
        padding: 45px 30px;
      }
      .ttl_wrap {
        dt {
          font-size: 36px;
        }
        dd {
          font-size: 11px;
          margin-top: 7px;
        }
      }
      .tel_wrap {
        margin: 25px auto 0;
        max-inline-size: max-content;
        dt a {
          letter-spacing: .02em;
        }
        dd {
          margin-top: 3px;
          margin-left: -30px;
          text-align: center;
          font-size: 11px;
        }
      }
      .btn_wrap {
        margin-top: 20px;
        a {
          width: min(100%, 330px);
          height: 60px;
          border-radius: 30px;
          padding-right: 8px;
          margin: auto;
        }
      }
    }
  
    .main_wrap {
      padding-block: 256px 24px;
  
      .top_wrap {
        display: block;

        .left_wrap {
          display: block;
          .logo {
            width: 100px;
            margin: auto;
          }
          .text_wrap {
            text-align: center;
            margin-top: 20px;
            dl {
              dt {
                margin-bottom: 16px;
              }
            }
            .map {
              margin-top: 15px;
              a {
                padding: 0 0 5px 2px;
                margin: auto;
              }
            }
          }
        }
  
        nav {
          margin: 38px auto 0;
          max-width: 300px;
          > ul {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 30px;
            > li {
              padding-left: 12px;
              &::before {
                margin-right: 7px;
                top: 7px;
              }
            }
            > li {
              &:nth-of-type(2){
                order: 2;
              }
              &:nth-of-type(3){
                grid-column: 1 / 2;
                grid-row: 3 / 6;
              }
              &:nth-of-type(4){
                grid-column: 1 / 2;
                grid-row: 6 / 7;
              }
              &:nth-of-type(5){
                grid-column: 2 / 3;
                grid-row: 1 / 3;
              }
              &:nth-of-type(6){
                order: 3;
              }
              &:nth-of-type(7){
                order: 4;
              }
            }
          }
          .sub_list {
            margin-top: 12px;
            li {
              + li {
                margin-top: 7px;
              }
              a {
                &::before {
                  margin-right: 5px;
                }
              }
            }
          }
        }
  
      }
  
      .bottom_wrap {
        margin-top: 30px;
        padding-top: 17px;
        display: block;
        .copy {
          text-align: center;
        }
        .right_wrap {
          display: block;
          margin-top: 12px;
          position: relative;
          ul {
            justify-content: center;
          }
          .login {
            position: absolute;
            right: 0;
            top: -2px;
          }
        }
      }
    }
  }
  
  .pagetop {
    left: 0;
    right: 0;
    top: 64px;
    margin: auto;
  }
}