.rwd002-gnav {
  @include media-down(lg) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: transparent;
    transform: translateX(100%);
    transition: background-color 0.2s ease-in-out, transform 0s 0.5s;
  }

  &.is-active {
    // メニューが開いたときのオーバーレイ
    @include media-down(lg) {
      background-color: fade-out($black, 0.2);
      transform: translateX(0%);
      transition: background-color 0.2s ease-in-out, transform 0s 0s;
    }
  }

  &__inner {
    // 格納メニュー
    @include media-down(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: $gnav-height-md map-get($spacers, 2) map-get($spacers, 2);
      width: calc(100vw - map-get($spacers, 5));
      transform: translateX(100%);
      transition: $transition-base;
      background-color: $white;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    @include media-down(md) {
      padding-top: 70px;
    }
  }

  .is-active &__inner {
    @include media-down(lg) {
      transform: translateX(0%);
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @include media-up(lg) {
      margin-right: -0.75em;
      align-items: stretch;
      flex-direction: row;
    }
  }

  &__copyright {
    @include media-up(lg) {
      display: none;
    }
    @include media-down(lg) {
      font-size: $font-size-xs;
      color: tint($body-color, 50%);
    }
  }
}

.rwd002-gnav-sublist {
  // sitemap.phpなどのシステムメニュー
  list-style: none;
  padding: 0;
  @include media-up(lg) {
    display: none;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
    margin: map-get($spacers, 2) 0;
    color: tint($body-color, 50%);
    font-size: $font-size-xs;
    margin-left: -0.5em;
    margin-right: -0.5em;
    &__item{
      position: relative;
      padding: 0.2em 0.6em;
    }
    &__item + &__item:before{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%,-50%);
      content: "";
      height: 1em;
      width: 1px;
      background-color: tint($body-color, 50%);
    }
  }
}

// グローバルナビ　Item(親)
.rwd002-gnav-item {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9375rem;
  letter-spacing: 0.14em;
  text-indent: 0.14em;
  font-feature-settings: "palt";

  @include media-down(lg) {
    border: solid $gray-300;
    border-width: 1px 0;
    & + & {
      border-top-width: 0;
    }
    &:last-of-type {
      border: none;
      margin-top: 20px;
      a {
        max-width: 200px;
        margin: auto;
        &::after {
          display: none;
        }
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    padding: 0.25em 0 0.25em 1.5em;
    min-height: 3.3em;
    &.contact {
      display: grid;
      place-content: center;
      width: 140px;
      height: 38px;
      border-radius: 19px;
      background-color: $thema-gr;
      min-height: unset;
      padding: 0;
      margin: auto;
      span {
        color: #fff;
      }
      &:hover {
        background-color: $thema-bl;
      }
    }

    @include media-down(lg) {
      flex-grow: 1;
      flex-basis: calc(100% - 50px);
    }
    
    // PC表示のメニュー装飾
    @include media-up(lg) {
      justify-content: center;
      height: 100%;
      padding: 0 14px;
      min-height: 100px;
      span {
        position: relative;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 14px;
        font-weight: 700;
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin: auto;
          height: 2px;
          width: min(100%, 64px);
          background-color: $thema-gr;
          transition: $transition-base;
          opacity: 0;
          transform: scaleX(0);
          transform-origin: left;
        }
      }
      @include max(1120px){
        padding: 0 clamp(5px, 0.86vw, 14px);
        span {
          font-size: clamp(11px, 1vw, 14px);
        }
      }
      &.contact span::after {
        display: none;
      }
      &.current, &:hover {
        span:after {
          opacity: 1;
          transform: scaleX(1);
        }
      }
      &.contact {
        margin-left: clamp(5px, 0.86vw, 14px);
        width: clamp(100px, 10vw, 140px);
      }
    }

    // SP表示のメニュー装飾
    @include media-down(lg) {
      &:after {
        position: absolute;
        content: "";
        top: calc(50% - 0.3em);
        left: 0;
        width: 1.5em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svgUrlFunc(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$body-color}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
        );
      }
    }
  }
}

// グローバルナビ subnav(子)

/* HTML構成例
<div class="rwd002-subnav">
  <div class="rwd002-subnav__inner">
    <ul class="rwd002-subnav__list">
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー01</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー02</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー03</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
    </ul>
    <!-- /.rwd002-subnav__list -->
  </div>
  <!-- /.rwd002-subnav__inner -->
</div>
<!-- /.rwd002-subnav -->
*/

.rwd002-subnav {
  @include media-up(lg) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 30px;
    margin: 0;
    transform-origin: 0 0;
    transform: scale(1, 0);
    transition: transform 0.2s 0.2s ease-in-out;
    box-sizing: border-box;

    &__inner {
      background-color: #F4F6F9;
      max-width: 960px;
      height: 210px;
      padding-inline: 30px;
      margin-inline: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 17px;
        font-weight: 700;
        line-height: 1;
        width: 165px;
      }
    }

    &__btn {
      display: none;
    }

    .rwd002-gnav-item:hover & {
      transform: scale(1, 1);
    }

    &.collapse:not(.show){
      display: block;
    }
  }
  @include media-down(lg) {
    flex-basis: 100%;
  }

  &__inner {
    @include media-up(lg) {
      margin: 0 auto;
    }
  }

  &__list {
    list-style: none;
    padding: 0 1.5em;

    @include media-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 50px;
      li a {
        font-size: 14px;
        display: block;
        width: 185px;
        border-bottom: 1px solid #D6DEE8;
        padding: 12px 0 !important;
        line-height: 1;
        position: relative;
        span {
          transition: .3s;
        }
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: $thema-gr;
          border-radius: 50%;
        }
        &::before {
          width: 18px;
          height: 18px;
          opacity: .2;
          transition: .3s;
          right: 0;
        }
        &::after {
          width: 6px;
          height: 6px;
          right: 6px;
        }
        &:hover {
          border-color: $thema-gr;
          span {
            color: $thema-gr;
          }
          &::before {
            transform: scale(1.3);
          }
        }
      }
    }

    @include media-down(lg) {
      padding-right: 0;
      padding-bottom: 1em;
    }
  }

  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(lg) {
      display: none;
    }
    @include media-down(lg) {
      position: relative;
      flex-basis: 50px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 1px;
        background-color: $primary;
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
}
.rwd002-header .rwd002-subnav-item {
  &__link {
    display: block;
    transition: $transition-base;
    @include media-up(lg) {
      padding: 0.75em 1em;
    }
    @include media-down(lg) {
      padding: 0.2em 0;
    }
  }
}
