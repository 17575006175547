.is--news {
  #allbox {
    @include pc {
      overflow: unset;
    }
  }

  .rwd002-taglist{
    &__cats {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px 15px;
      a {
        display: grid;
        place-content: center;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        box-sizing: border-box;
        border: 2px solid #373737;
        font-size: 14px;
        font-weight: 700;
        color: #373737;
        margin: 0;
        &:hover, &.current {
          background-color: #373737;
          border-color: #373737;
          color: #fff;
        }
      }
    }
    
    &__tags {
      display: none;
    }
  
    &__list {
      gap: 10px 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      a {
        display: block;
        line-height: 1;
        font-size: 11px;
        font-weight: 700;
        color: var(--tag-bl);
      }
    }

    @include sp {
      padding-inline: 25px;
      &__cats {
        display: grid;
        grid-template-columns: repeat(2,90px);
        a {
          width: 100%;
        }
      }
    
      &__catBtn {
        font-size: 14px;
        padding-block: 6px;
      }
    
      &__list {
        gap: 6px 14px;
        align-items: flex-start;
      }
    }
  }

  &.is--list {
    .rwd002-container {
      margin-block: 2.25rem;
    }

    .news-list {
      &__inner {
        max-width: 960px;
        margin: auto;
        > div + div {
          border-top: 1px solid #D6DEE8;
        }
      }

      a {
        display: grid;
        grid-template-columns: 160px 1fr;
        column-gap: 46px;
        align-items: center;
        padding: 20px 42px 20px 0;
        box-sizing: border-box;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 20px;
          height: 18px;
          background: url(/assets/img/common/arrow.svg) no-repeat 0 / contain;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: .3s;
        }
        figure {
          border-radius: 3px;
          overflow: hidden;
          aspect-ratio: 1 / 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
          }
        }
        .text_wrap {
          .info {
            display: flex;
            align-items: center;
            column-gap: 15px;
            line-height: 1;
            .date {
              font-size: 12px;
              @include Work(500);
            }
            .cate {
              font-size: 12px;
              font-weight: 700;
              color: $thema-gr;
            }
          }
          .ttl {
            margin-top: 22px;
            line-height: 1.5;
            span {
              font-size: 15px;
              font-weight: 700;
              background: linear-gradient($font-color, $font-color) no-repeat left bottom / 100% 0;
              transition: .3s;
            }
          }
        }
        &:hover {
          &::before {
            transform: translateX(5px);
          }
          img {
            transform: scale(1.1);
          }
          .text_wrap .ttl span {
            background-size: 100% 1px;
          }
        }
      }
    }

    @include sp {
      .rwd002-container {
        margin-bottom: 40px;
      }
      .content-head-banner {
        + .rwd002-taglist {
          padding-top: 30px;
          &::before {
            width: 100%;
          }
        }
      }

      .news-list {
        margin-inline: 25px;
        a {
          display: grid;
          grid-template-columns: 78px 1fr;
          column-gap: 18px;
          padding: 20px 34px 20px 0;
          &::before {
            width: 11px;
            height: 10px;
          }
          figure {
            border-radius: 2px;
          }
          .text_wrap {
            .info {
              column-gap: 17px;
            }
            .ttl {
              margin-top: 8px;
              line-height: 1.4;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  &.is--detail {
    .rwd002-detail {
      margin-top: 30px;
      &__head {
        max-width: 800px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 15px;
      }
      &__date {
        order: 1;
        font-size: 14px;
        @include Work(500);
        padding: 0;
        margin: 0;
        color: $font-color;
      }
      &__category {
        order: 2;
        font-size: 14px;
        font-weight: 700;
        color: $thema-gr;
        margin: 0;
        padding: 0;
        min-width: unset;
        border: none;
      }
      &__title {
        order: 3;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        margin-top: 8px;
        width: 100%;
      }
      &__image {
        margin-bottom: 90px;
      }
      &__entry {
        margin-top: 50px;
        max-width: 800px;
        .editor-template {
          .bold-title {
            font-size: 18px;
            font-weight: 700;
          }
          p {
            font-size: 15px;
          }
        }
      }

      @include sp {
        &__date {
          font-size: 12px;
        }
        &__category {
          font-size: 12px;
        }
        &__title {
          margin-top: 10px;
          font-size: 22px;
          line-height: 1.4;
        }
        &__image {
          margin-bottom: 45px;
        }
        &__entry {
          margin-top: 45px;
          .editor-template {
            .bold-title {
              font-size: 16px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}